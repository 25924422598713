.recall-single-product {
  width: 70%;
  margin: 0 auto;
  margin-top: 4rem;
}

.recall-single-product-flex {
  margin-top: 4rem;
  width: 100%;
  display: flex;
  gap: 5rem;
  margin-bottom: 6rem;
}
.recall-single-product-flex > div {
  width: 50%;
}
.recall-single-product-left-img {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fbfbfb;
  padding: 60px 20px;
}
.recall-single-product-left-img img {
  width: 100%;
  display: flex;
  width: 60%;
  margin: 0 auto;
}
.recall-single-product-name-price {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.recall-single-product-name {
  color: rgba(27, 27, 27, 0.9);
  font-size: 36px;
  font-weight: 600;
  line-height: 130%; /* 46.8px */
  letter-spacing: -1.08px;
}
.recall-single-product-price {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 100%; /* 70.2px */
  letter-spacing: -1.08px;
}

.recall-single-product-price span {
  font-family: "Inter", sans-serif;
  color: rgba(0, 0, 0, 0.7);
  font-size: 36px;
}
.recall-single-product-desc {
  color: rgba(0, 0, 0, 0.6);
  font-size: 18px;
  font-weight: 500;
  line-height: 146%; /* 32.12px */
  margin-top: 10px;
  letter-spacing: -0.44px;
}
.recall-tabs {
  margin-top: 36px;
  margin-bottom: 2rem;
}

.recall-tabs .MuiTab-root {
  color: #333;
  font-size: 16px !important;
  font-family: "Poppins", sans-serif;
  text-transform: none !important;
  font-weight: 500;
  line-height: 130%; /* 23.4px */
  letter-spacing: -0.54px;
}
.single-product-size-list {
  display: grid;

  width: 100%;
  grid-template-columns: repeat(3, 1fr);

  /* flex-wrap: wrap; */
  gap: 18px;
}
.single-product-size-list-item {
  width: 100%;
  background: #f9f9f9;
  padding: 13px;
  padding-bottom: 10px;
}
.single-product-size-list-item > span {
  color: rgba(0, 0, 0, 0.8);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 130%; /* 24.126px */
  letter-spacing: -0.371px;
  margin-bottom: 9px;
  opacity: 0.75;
}
.single-product-size-list-item.active {
  background: #f0f9fd;
}
.single-product-size-list-item p {
  color: rgba(0, 0, 0, 0.7);
  font-family: "Inter", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 130%; /* 46.729px */
  letter-spacing: -1.478px;
  margin-top: 10px;
}
.single-product-size-list-item p span {
  color: rgba(126, 126, 126, 0.7);
  font-size: 25px;
  font-weight: 600;
}
.recall-single-product-quantity {
  margin-top: 2.5rem;
}
.recall-single-product-quantity h3 {
  color: #333;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 23.4px */
  letter-spacing: -0.54px;
}
.recall-single-product-quantity button {
  background-color: #fff;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.368px;
  border-radius: 6px;
  border: 1.14px solid #000;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.recall-single-product-quantity-flex {
  display: flex;
  margin-top: 2rem;
  /* align-items: center; */
  gap: 15px;
  padding-bottom: 3rem;
}
.recall-single-product-quantity-count {
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  padding: 0 13px;
}
.recall-single-product-quantity-count > div {
  color: #333;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 130%; /* 21.691px */
  letter-spacing: -0.501px;
  cursor: pointer;
}
.recall-single-product-impact {
  border-radius: 30px;
  border: 2px solid #e4e4e4;
  background: #fff;
  display: flex;
  gap: 3rem;
  padding: 10px;
}
.recall-single-product-impact-img {
  border-radius: 14px;
  border: 1px solid #e9e9e9;
  background: #f4f4f4;
}
.recall-single-product-impact-text {
  display: flex;
  gap: 10px;
}
.recall-single-product-impact-text p {
  color: #606060;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 46.8px */
  letter-spacing: -1.08px;
}

.recall-single-product-impact-text p span {
  color: #000;
}
.recall-single-product-impact-text {
  display: flex;
  margin-top: 12px;
  height: fit-content;
}
.recall-single-product-impact-text-bar {
  width: 10px;
  background: #89cff0;
}

.recall-recommended-products {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.recall-addded-item-img-flex {
  display: flex;
  gap: 15px;
  width: 58%;
  align-items: flex-start;
}
.recall-addded-item-img {
  background-color: #f9f9f9;
  padding: 1rem;
  border-radius: 14px;
}

.recall-addded-item-img img {
  width: 90px;
}
.recall-addded-item-info {
  display: flex;
  flex-direction: column;
  /* width: 40%; */
  gap: 12px;
}
.recall-addded-item-info-name {
  color: #000;
  font-size: 16.685px;
  font-weight: 600;
  line-height: 130%; /* 21.691px */
  letter-spacing: -0.501px;
}
.recall-addded-item-info-desc {
  color: #818181;
  font-size: 16px;
  font-weight: 500;
  line-height: 130%; /* 21.691px */
  letter-spacing: -0.501px;
}

.recall-addded-item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 30px;
}
.recall-addded-item .recall-single-product-quantity-count {
  padding: 12px;
}
.recall-addded-item-price {
  color: rgba(0, 0, 0, 0.7);
  font-family: "Inter", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 130%; /* 48.73px */
  letter-spacing: -1.125px;
}
.recall-addded-item-price span {
  font-family: "Inter", sans-serif;
  color: rgba(126, 126, 126, 0.7);
  font-size: 24px;
}
.recall-added-buttons {
  display: flex;
  gap: 10px;
  width: 100%;
  margin-top: 1.4rem;
}
.recall-added-buttons button {
  width: 100%;
  padding: 6px;
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.368px;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #000;
}

.recall-added-buttons button.secondary {
  background: #89cff0;
  border: none;
  color: #fff;
}
.recall-added-buttons button.secondary img {
  width: 20px;
}
.recall-modal-recommendations {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}
.recall-modal-recommendations .category-product button {
  font-size: 14px;
}
.recall-modal-recommendations .category-product-name {
  font-size: 18px;
}
.recall-modal-recommendations .category-product-desc {
  font-size: 11px;
}
.recall-modal-recommendations .category-product-price {
  font-size: 22px;
}
.recall-modal-recommendations .category-product-price span {
  font-size: 12px;
}
.recall-modal-recommendations .category-product-pills > div {
  font-size: 10px;
}
.recall-modal-recommendations-wrap {
  margin-top: 3rem;
}
.recall-modal-recommendations-wrap .header-text {
  margin-bottom: 2rem;
}

@media (max-width: 1200px) {
  .recall-single-product {
    width: 90%;
    margin-top: 2rem;
  }
}
@media (max-width: 1000px) {
  .recall-single-product-flex {
    flex-direction: column;
  }
  .recall-single-product-flex > div {
    width: 100%;
  }
  .recall-single-product-impact-text p {
    font-size: 24px;
  }
  .recall-single-product-impact-text p br {
    display: none;
  }
  .recall-single-product-impact {
    gap: 1.5rem;
  }
}
@media (max-width: 900px){
  .recall-modal-recommendations{
    grid-template-columns: repeat(2,1fr);
  }
}
@media (max-width: 700px) {

  .recall-addded-item{
    flex-direction: column;
  }
  .recall-single-product-quantity-count{
    /* margin-top: 10px; */
  }
  .recall-addded-item-img-flex{
    width: 100%;
  }
  .recall-single-product-impact-text p {
    font-size: 18px;
  }
  .recall-single-product-impact-img img {
    width: 80px;
  }
  .recall-single-product-flex {
    gap: 1rem;
  }
  .recall-single-product-name {
    font-size: 28px;
  }
  .recall-single-product-price {
    font-size: 36px;
  }
  .recall-single-product-price span {
    font-size: 26px;
  }
  .recall-single-product-desc {
    font-size: 16px;
  }
  .recall-tabs .MuiTab-root {
    font-size: 14px !important;
    padding: 0;
  }
  .single-product-size-list-item > span {
    font-size: 14px;
  }
  .single-product-size-list-item p {
    font-size: 22px;
  }
  .single-product-size-list-item p span{
    font-size: 18px;
  }
  .recall-single-product-quantity button{
    font-size: 16px;
  }
  .single-product-size-list{
    gap: 14px;
  }
  .recall-single-product-flex{
    margin-top: 1rem;
  }
  .recall-single-product-price span{
    font-size: 20px;
  }
  .single-product-size-list-item p{
    margin-top: 0;
  }
  .recall-single-product-price{
    font-size: 30px;
  }
  .recall-single-product-flex{
    margin-bottom: 1.4rem;
  }
  .recall-single-product-quantity button{
    padding: 6px 36px;
    font-size: 14px;
  }
}

@media (max-width: 600px){
  .recall-modal-recommendations{
    grid-template-columns: repeat(1,1fr);
  }
  .recall-added-buttons button{
    font-size: 14px;
  }
  
  .recall-added-buttons {
    flex-direction: column;
  }
  .category-product-img img{
    width: 65%;
  }
  .recall-addded-item-info-name{
    font-size: 15px;
  }
  .recall-addded-item .recall-single-product-quantity-count{
    padding: 10px;
  }
}