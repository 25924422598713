.recall-store-filter {
  border-radius: 14px;
  border: 1px solid #ececec;
  background: #f8f8f8;
  margin: 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
}
.recall-store-filter-header {
  color: rgba(27, 27, 27, 0.9);
  font-size: 20px;
  font-weight: 600;
  line-height: 130%; /* 31.2px */
  letter-spacing: -0.72px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.recall-store-filter-header img {
  width: 24px;
  display: flex;
}
.recall-store-filter-left {
  /* padding: 16px; */
  padding-left: 30px;
  padding-right: 0;
  display: flex;
  align-items: center;
  gap: 2rem;
}
.recall-store-filter-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}
.recall-store-filter-filters-item {
  cursor: pointer;
  color: rgba(27, 27, 27, 0.7);
  font-size: 16px;height: 65px;
  font-weight: 600;
  line-height: 130%; /* 26px */
  letter-spacing: -0.6px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.recall-store-filter-filters-item img {
  display: flex;
}
.recall-store-filter-right button {
  border-radius: 8px;
  background: #89cff0;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 130%; /* 26px */
  letter-spacing: -0.6px;
  padding: 16px 24px;
  height: 100%;
  border: none;
}
.filters-category {
  position: fixed;
  top: 150px;
  border-radius: 11.305px;
  background: #fff;
  box-shadow: 0px 0px 14.13174px 1.41317px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.filters-category-item {
  display: flex;
  align-items: center;
  min-width: 170px;
  gap: 7px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.28px;
}

@media (max-width: 1200px) {
  .recall-store-filter-filters-item {
    font-size: 14px;
  }
  .recall-store-filter-header {
    font-size: 16px;
  }
  .recall-store-filter-right button {
    font-size: 16px;
    padding: 10px 24px;
  }
  .recall-store-filter-filters {
    gap: 1rem;
  }
}
@media (max-width: 1200px) {
  .recall-store-filter {
    display: none;
  }
  .category-products{
    margin-top: 0;
  }
}
