.recall-store-profile-content {
  width: 90%;
  margin: 6rem auto;
  margin-bottom: 9rem;
}
.recall-store-profile-left-profile {
  border-radius: 16px;
  border: 2px dashed #b0b0b0;
  padding: 40px 20px;
  text-align: center;
  width: fit-content;
}
.recall-store-profile-left-profile-name {
  color: #2b2e2f;
  font-size: 36px;
  font-weight: 600;
  line-height: 130%; /* 46.8px */
  letter-spacing: -1.08px;
}
.recall-store-profile-left-profile-email {
  color: #939393;
  font-size: 20px;
  font-weight: 500;
  line-height: 130%; /* 28.6px */
  letter-spacing: -0.66px;
}
.recall-store-profile-left-profile-points {
  display: flex;
  align-content: center;
  gap: 10px;
  border-radius: 26.5px;
  background: rgba(255, 243, 164, 0.34);
  padding: 12px 32px;
  margin-top: 5px;
}

.recall-store-profile-flex {
  display: grid;
  margin-top: 3rem;
  gap: 4rem;
  grid-template-columns: 0.4fr 1fr;
}
.recall-store-profile-right-settings {
  display: flex;
  align-items: flex-start;
  gap: 3rem;
  width: 100%;
}

.recall-store-profile-right-settings > div {
  width: 100%;
}
.settings-header {
  color: #434343;
  font-size: 19px;
  font-weight: 600;
  letter-spacing: -0.383px;
}
.recall-store-profile-right-settings-item {
  width: 100%;
  border-radius: 10.767px;
  background: rgba(218, 218, 218, 0.21);
  padding: 14px;
  display: flex;
  margin-top:11px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.recall-store-profile-right-settings-item-name {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.335px;
}
.recall-store-profile-right-settings-item-arrow img {
  transition: all 0.3s ease-in-out;
}
.recall-store-profile-right-settings-item:hover
  .recall-store-profile-right-settings-item-arrow
  img {
  transform: translateX(4px);
}
