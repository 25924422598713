.create-account-modal h1 {
  color: #474747;

  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 55.073px */
  letter-spacing: -1.271px;
  margin-top: 15px;
}
.create-account-modal p {
  color: #5b5b5b;
  font-size: 20px;
  opacity: 0.85;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 31.356px */
  letter-spacing: -0.965px;
}
.create-account-modal {
  text-align: left;
}
.buttons {
  margin-top: 30px;
}
.buttons {
  text-align: center;
}
.buttons button {
  border-radius: 12.06px;
  background: #89cff0;
  width: 100%;
  padding: 10px;
  color: #fff;
  font-size: 20.602px;
  font-style: normal;
  font-weight: 600;
  border: none;
  line-height: normal;
  letter-spacing: -0.572px;
}
.buttons p {
  font-size: 18px;
  margin-top: 10px;
}
.buttons p span {
  color: #89cff0;
  cursor: pointer;
}
.create-account-modal img{
    width: 200px;
}