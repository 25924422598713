.recall-store-navbar,
.recall-store-navbar-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  z-index: 11;
  background: #fff;
  box-shadow: 0px 4px 38px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.5px);
  padding: 0px 5rem;
}

.recall-store-navbar-mobile {
  display: none;
}
.recall-store-navbar-store-icons-item {
  display: flex;
}
.recall-store-navbar-store-icons-profile > img {
  display: flex;
}
.recall-store-navbar-links {
  display: flex;
  height: 57px;
  align-items: center;
  gap: 2rem;
}
.recall-store-navbar-store-icons {
  display: flex;
  align-items: center;
  gap: 4.3rem;
  border-radius: 13px;
  background: #ebebeb;
  padding: 9px 25px;
}
.recall-store-navbar-logo {
  display: flex;
}
.recall-store-navbar-logo img {
  width: 200px;
}
.recall-store-navbar-store-icons-items {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.recall-store-navbar-links-item {
  position: relative;
  height: 100%;
  transition: all 0.3s ease-in-out;
}
.categories-dropdown {
  position: fixed;
  top: 57px;
  z-index: 20;
  border-radius: 11.305px;
  background: #fff;
  box-shadow: 0px 0px 14.131736755371094px 1.4131736755371094px
    rgba(0, 0, 0, 0.25);
  width: 240px;
  padding: 20px;
}
.profile-dropdown {
  right: 4%;
  padding: 25px;
  top: 40px;
  width: 350px;
  padding-bottom: 34px;
}
.recall-store-navbar-links-item:hover {
  cursor: pointer;
  /* transform: translateY(-1px); */
}

.recall-store-navbar-links-item p {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-weight: 600;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  letter-spacing: -0.32px;
}
.recall-store-navbar-store-icons-profile {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.recall-store-navbar-store-icons-profile-name {
  cursor: pointer;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: 23.95px; /* 133.057% */
  letter-spacing: -0.54px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.cart-count-icon {
  position: relative;
  cursor: pointer;
}
.cart-count-number {
  position: absolute;
  top: -9px;
  right: -9px;
  background-color: #ed2027;
  width: 18px;
  height: 18px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 9.497px;
  font-weight: 600;
  line-height: 12.636px; /* 133.057% */
  letter-spacing: -0.285px;
}
.header-text-bar-flex {
  display: flex;
  gap: 8px;
}
.header-text-bar {
  background: #89cff0;
}
.header-text-arrow {
  display: flex;
}
.header-text-text {
  color: #000;
  line-height: 110%;
  font-weight: 600;
  letter-spacing: -0.32px;
}
.header-text {
  display: flex;
  align-items: center;
  gap: 11px;
}

.categories-dropdown-list {
  margin-left: 4px;
  margin-top: 13px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
}
.recall-store-navbar-links-item-categories-item-flex {
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.28px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: all 0.3s ease-in-out;
}

.recall-store-navbar-links-item-categories-item-flex {
  padding: 1px 5px;
  border-radius: 3px;
}
.categories-dropdown-list-products-item-active {
  background-color: #eee;
}
.recall-store-navbar-links-item-categories-item-flex:hover {
  cursor: pointer;
  background-color: #eee;
  transform: translateX(1.6px);
  color: rgba(0, 0, 0, 1);
}
.categories-dropdown-list-products {
  margin-left: 14px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 7px;
}
.categories-dropdown-list-products-item {
  display: flex;
  align-items: center;
  gap: 5px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.28px;
}
.ecom-login-signup {
  display: flex;
  gap: 17px;
}
.ecom-login-signup p {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}
.ecom-login-signup p img {
  opacity: 0.7;
  transition: all 0.3s ease-in-out;
}
.ecom-login-signup p:hover img {
  transform: translateX(2px);
  opacity: 1;
}
.profile-dropdown-content {
  margin-top: 15px;
}
.profile-dropdown-category-profile p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 7px;
  margin-left: 6px;
  letter-spacing: -0.48px;
}

.profile-dropdown-category-profile-box {
  padding: 8px;
  border-radius: 8.744px;
  padding-left: 12px;
  border: 1px solid #ebebeb;
  background: rgba(231, 239, 241, 0.6);
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  gap: 9px;
}
.profile-dropdown-category-profile-box-item {
  display: flex;
  align-items: center;
  gap: 6px;
}
.profile-box-item-username {
  color: #3e3e3e;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.333px;
}
.profile-box-item-points {
  color: rgba(0, 0, 0, 0.6);
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.271px;
}

.profile-box-item-points span {
  color: #000;
  font-weight: 600;
}
.profile-dropdown-category-profile-item-box {
  display: flex;
  border-radius: 8.744px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  background: #f3f3f3;
  cursor: pointer;
  padding: 10px;
  transition: all 0.3s ease-in-out;
}
.profile-dropdown-category-profile-item-box:hover {
  background: #ebebeb;
}
.profile-dropdown-category-profile-item-box-arrow img {
  transition: all 0.3s ease-in-out;
}
.profile-dropdown-logout {
  transition: all 0.3s ease-in-out;
}
.profile-dropdown-logout:hover {
  cursor: pointer;
  transform: translateX(1.6px);
}
.profile-dropdown-category-profile-item-box:hover
  .profile-dropdown-category-profile-item-box-arrow
  img {
  transform: translateX(2px);
}
.profile-dropdown-category-profile-item-box-name {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.251px;
}
.profile-dropdown-category-profile-item-box-name img {
  width: 22px;
}
.profile-dropdown-logout {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #ce6d7e;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.292px;
  margin-left: 10px;
}
.profile-dropdown-logout img {
  width: 22px;
}
.categories-dropdown-list-products {
  position: absolute;
  left: 50%;
  top: -8px;
  background-color: #fff;
  border-radius: 10.363px;
  box-shadow: 0px 0px 30px 1.29541px rgba(0, 0, 0, 0.14);
  padding: 1rem;
  z-index: 14;
  width: 300px;
}
.recall-store-navbar-links-item-categories-item {
  position: relative;
}
@media (max-width: 1300px) {
  .recall-store-navbar-store-icons {
    gap: 2rem;
  }
  .recall-store-navbar-links-item p {
    font-size: 14px;
  }
  .recall-store-navbar-links {
    gap: 1.3rem;
  }
}
@media (max-width: 1200px) {
  .recall-store-navbar-mobile {
    display: flex;
  }
  .recall-store-navbar {
    display: none;
  }
  .recall-store-navbar-mobile {
    padding: 6px 3rem;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(6.5px);
  }
  .recall-mobile-nav-menu {
    display: flex;
    cursor: pointer;
  }
}
@media (max-width: 600px) {
  .recall-store-navbar-mobile {
    padding: 8px 1rem;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(6.5px);
  }
  .recall-store-navbar-logo img {
    width: 160px;
  }
}
