.recall-store-banner {
  background-color: #89cff0;
  padding: 5rem 0;
  padding-bottom: 6rem;
}
.recall-store-banner-content {
  width: 90%;

  margin: 0 auto;
  color: #fff;
}
.recall-store-banner-breadcrumb {
  color: #fff;
  font-size: 23.324px;
  font-weight: 500;
  line-height: 130%; /* 30.321px */
  letter-spacing: -0.7px;
}
.recall-store-banner-search h1 {
  color: #fff;
  text-align: center;
  font-size: 42px;
  font-weight: 600;
  line-height: 130%; /* 67.6px */
  letter-spacing: -1px;
}
.recall-store-banner-search {
  text-align: center;
}
.recall-store-landing-section-one-search {
  text-align: center;
}
.recall-store-landing-section-one-search-category {
  width: 40% !important;
  margin: 0 auto;
}
.recall-store-banner-search {
  margin-top: 4rem;
}
.recall-store-landing-section-one-search button {
  bottom: 3px !important;
  right: 3px !important;
}

.recall-categories-page-content {
  width: 90%;
  margin: 0 auto;
  margin-top: 60px;
}
.recall-categories-page-content-desc h1 {
  color: rgba(0, 0, 0, 0.8);
  font-size: 31px;
  font-weight: 600;
  line-height: 130%; /* 40.3px */
  letter-spacing: -0.62px;
  margin-top: 20px;
}
.recall-categories-page-content-desc {
  width: 80%;
}

.recall-categories-page-content-desc p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 18px;
  font-weight: 500;
  line-height: 130%; /* 26px */
  letter-spacing: -0.4px;
  margin-top: 0.6rem;
}
.category-product {
  border-radius: 30.831px;
  border: 1.1px solid #d7d7d7;
  padding: 9px;
}
.category-product-img {
  width: 100%;
  border-radius: 21.345px;
  background: #f0f0f0;
  padding: 20px;
}
.category-product-img img {
  width: 80%;
  margin: 0 auto;
  display: flex;
  text-align: center;
}
.category-products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 5rem;
  margin-top: 5rem;
  gap: 1.5rem;
}
.category-product {
  width: 100%;
}
.category-product button {
  width: 100%;
  border-radius: 10.672px;
  background: #89cff0;
  padding: 10px;
  color: #fff;
  font-size: 18.973px;
  font-weight: 600;
  border: none;
  margin-bottom: 15px;
  letter-spacing: -0.379px;
  margin-top: 1rem;
}
.category-product-details {
  padding: 0 12px;
  margin-top: 10px;
}
.category-product-name-price {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.category-product-name {
  color: #000;
  font-size: 23.716px;
  font-weight: 600;
  line-height: 130%; /* 30.831px */
  letter-spacing: -0.474px;
}
.category-product-price {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 33.203px;
  font-weight: 600;
  line-height: 130%; /* 43.163px */
  letter-spacing: -0.664px;
}
.category-product-price span {
  color: rgba(0, 0, 0, 0.7);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -0.332px;
}
.category-product-pills {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.category-product-pills > div {
  padding: 3px 16px;
  border-radius: 14.506px;
  border: 1.261px solid #f2f2f2;
  background: #f9f9f9;
  font-size: 14px;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: -0.303px;
}
.category-product-pill span {
  color: rgba(0, 0, 0, 0.65);
}
.category-product-desc {
  color: rgba(40, 40, 40, 0.65);
  font-size: 16px;
  font-weight: 500;
  line-height: 130%; /* 21.582px */
  letter-spacing: -0.498px;
  margin-top: 5px;
  opacity: 0.8;
  margin-bottom: 14px;
}
.recall-store-banner-breadcrumb {
  display: flex;
  align-items: center;
  gap: 6px;
}
.recall-store-banner-breadcrumb p {
  cursor: pointer;
}
.distinct-products {
  margin-top: 40px;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);
  gap: 2rem;
}
.sub-category-container {
  width: 90%;
  margin: 0 auto;
  margin-top: 30px;
}
.sub-category-container .category-products {
  margin-top: 2rem;
}
.number-of-results {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.4px;
  color: #575757;
  margin-top: 20px;
}
.number-of-results span {
  color: #000;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}
.category-product-name-desc {
  font-size: 20px;
  font-family: "Inter", sans-serif;
  letter-spacing: -0.4px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #575757;
}
.sub-category-container .category-product-price {
  font-family: "Inter", sans-serif;
  font-size: 28px;
  margin-bottom: 5px;
}
.category-product-price span {
  font-family: "Inter", sans-serif;
}
.mobile-filters {
  display: none;
}
.mobile-category-products {
  display: none;
}
.payment-success {
  background-color: #35c28c;
}
.payment-failed {
  background-color: #cb3532;
}
.payment-status-img img {
  width: 300px;
  margin: 0 auto;
  margin-bottom: 2rem;
}
.payment-status-img{
  margin-top: 3rem !important;
}
@media (max-width: 1200px) {
  .mobile-filters {
    background-color: #f8f8f8;
    border: 1px solid #d7d7d7;
    border-radius: 10px;
    padding: 10px;
    margin-top: 20px;
    display: block;
  }
  .mobile-recall-points-filter {
    display: flex;
    align-content: center;
    gap: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
  }
  .mobile-filters-header {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .mobile-filters p {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.3px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .mobile-filters-header-content {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .mobile-filters-header {
    display: flex;
    cursor: pointer;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .mobile-filters-list-item {
    margin-bottom: 10px;
    cursor: pointer;
  }
  .mobile-filters-list-header img {
    transition: all 0.2s ease-in-out;
  }
  .mobile-filters-list-header .open-subfilter {
    transform: rotate(90deg);
  }
  .mobile-filters-list {
    margin-top: 20px;
    max-width: 600px;
  }
  .mobile-filters-category-item {
    font-family: "Inter", sans-serif;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
  }

  .mobile-filters-list-header p {
    font-size: 18px;
  }
  .mobile-filters-list-item {
    border-radius: 8px;
    border: 1px dashed #cbcbcb;
    padding: 5px;
    padding-left: 13px;
  }
  .mobile-filters-list-header {
    display: flex;

    justify-content: space-between;
    align-items: center;
  }
  .mobile-filters-button {
    width: 100%;
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
  .mobile-filters-button button {
    border-radius: 8px;
    padding: 7px;
    border: none;
    background: #89cff0;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 20.8px */
    letter-spacing: -0.48px;
  }
  .mobile-filters-button button.secondary {
    background: #fff;
    color: #000;
    border: 1px solid #d7d7d7;
  }
  .mobile-filters-category {
    margin-top: 10px;
  }
  .mobile-filters-header-icon img {
    display: flex;
    transition: all 0.2s ease-in-out;
  }
  .mobile-filters-header-icon.open img {
    transform: rotate(90deg);
  }
  .mobile-filters p img {
    width: 30px;
  }
  .distinct-products {
    grid-template-columns: repeat(4, 1fr);
  }
  .recall-store-landing-section-one-search-category {
    width: 70% !important;
  }
  .category-products {
    grid-template-columns: repeat(2, 1fr);
  }
  .category-product button {
    font-size: 16px;
  }
  .category-product-desc {
    font-size: 14px;
  }
  .recall-store-banner-search h1 {
    font-size: 36px;
  }
}

@media (max-width: 600px) {
  .category-product-mobile-flex {
    display: flex;
    gap: 12px;
  }

  .category-product-mobile-flex .category-product-img {
    width: 80%;
  }
  .category-product-mobile-flex .category-product-name {
    font-size: 20px;
  }
  .mobile-category-products .category-product-pills {
    margin-top: 10px;
  }
  .mobile-category-products .category-product {
    border-radius: 12px;
  }
  .mobile-category-products .category-product button {
    margin-bottom: 0;
  }
  .category-product-mobile-flex .category-product-desc {
    margin-bottom: 0;
  }
  .category-products {
    display: none;
  }
  .mobile-category-products {
    display: grid;
  }
  .mobile-filters {
    margin-bottom: 30px;
  }

  .recall-store-banner-breadcrumb p {
    font-size: 15px;
  }
  .mobile-filters p {
    font-size: 18px;
  }
  .number-of-results {
    font-size: 18px;
  }
  .mobile-filters-list-header p {
    font-size: 16px;
  }
  .distinct-products {
    margin-bottom: 34px;
  }
  .filters-wrap {
    display: none;
  }
  .distinct-products {
    margin-top: 20px;
  }
  .recall-categories-page-content .category-products {
    margin-top: 26px;
  }
  .recall-store-banner-breadcrumb {
    gap: 3px;
  }
  .recall-categories-page-content {
    margin-top: 25px;
  }
  .recall-store-banner-breadcrumb img {
    width: 20px;
  }
  .distinct-products {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    width: 80%;
  }
  .recall-store-banner-search h1 {
    font-size: 28px;
  }
  .recall-store-banner {
    padding-bottom: 3.5rem;
  }

  .recall-store-landing-section-one-search-category {
    width: 100% !important;
  }
  .recall-single-product-left-img {
    padding: 40px 20px;
  }
  .recall-store-banner-breadcrumb {
    font-size: 18px;
  }
  .recall-store-banner-search {
    margin-top: 2rem;
  }
  .recall-categories-page-content-desc {
    width: 100%;
  }
  .recall-categories-page-content-desc h1 {
    font-size: 22px;
  }
  .recall-categories-page-content-desc p {
    font-size: 14px;
  }
  .category-products {
    grid-template-columns: repeat(1, 1fr);
  }
}
