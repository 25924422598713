.recall-store-landing-section-one {
  background-image: url("../../../assets/EcommerceBg/ecombg.png");
  width: 100%;
  padding: 200px 5rem;
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
}
.recall-store-landing-section-heading h1 {
  color: rgba(27, 27, 27, 0.9);
  font-size: 55.124px;
  font-weight: 600;
  line-height: 130%; /* 71.661px */
  letter-spacing: -1.654px;
}

.recall-store-landing-section-heading-description {
  color: #4d5562;
  font-size: 24px;
  font-weight: 400;
  line-height: 163%; /* 48.256px */
  letter-spacing: -0.888px;
  margin-top: 20px;
}
.recall-store-landing-section-heading-content {
  max-width: 800px;
  width: 80%;
}
.recall-store-landing-section-one-search {
  position: relative;
  width: 65%;
  margin-top: 15px;
}
.recall-store-landing-section-one-search img {
  position: absolute;
  left: 14px;
  bottom: 14px;
  z-index: 3;
}
.recall-store-landing-section-one-search input {
  border-radius: 9px;
  border: 1px solid #ccc;
  background: rgba(255, 255, 255, 1);
  backdrop-filter: blur(25.5px);
  padding: 13px;
  padding-left: 47px;
  margin-top: 15px;
  width: 100%;
  color: rgba(0, 0, 0, 0.7);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.36px;
}
.recall-store-landing-section-one-search input::placeholder {
  color: rgba(0, 0, 0, 0.7);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.36px;
}
.recall-store-landing-section-one-search button {
  position: absolute;
  border-radius: 6px;
  background: #89cff0;
  border: none;
  padding: 12px 32px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 23.95px; /* 133.057% */
  letter-spacing: -0.54px;
  bottom: 6px;
  right: 6px;
}
.max-width {
  width: 90%;
  margin: 0 auto;
}
.recall-store-landing-section-two {
  margin-top: 4rem;
}
.recall-store-categories-list {
  margin-top: 42px;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(7, 1fr);
  gap: 1.5rem;
}
.recall-store-categories-list-item {
  border-radius: 18.232px;
  border: 1.2px solid #d5d5d5;
  padding: 9px;
}
.recall-store-categories-list-item {
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.recall-store-categories-list-item .recall-store-categories-list-item-img {
  width: 100%;
}
.recall-store-categories-list-item .recall-store-categories-list-item-img img {
  width: 100%;
}
.recall-store-categories-list-item-info {
  display: flex;
  align-items: center;
  padding: 0 4px;
  justify-content: space-between;
  color: #323232;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.677px;
}

.recall-store-categories-list-item:hover {
  cursor: pointer;
  transform: scale(1.01);
}
.recall-store-categories-list-item .recall-store-categories-list-item-info img {
  transition: all 0.3s ease-in-out;
}
.recall-store-categories-list-item:hover
  .recall-store-categories-list-item-info
  img {
  transform: scale(1.1) rotate(-45deg);
}

.recall-store-landing-section-three {
  margin-top: 8rem;
}
.recall-store-landing-section-three-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4rem;
}
.recall-store-landing-section-three-flex > div {
  width: 50%;
}
.recall-store-landing-section-three-left img {
  width: 80%;
}
.recall-store-landing-section-three-right button {
  border-radius: 6px;
  border: 1.2px solid #000;
  padding: 12px 50px;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.368px;
  background-color: transparent;
  display: flex;
  align-items: center;
  margin-top: 60px;
  gap: 10px;
}
.recall-store-landing-section-three-right button img {
  width: 25px;
  display: flex;
}
.recall-store-landing-section-four {
  margin-top: 10rem;
}
.recall-store-landing-section-four-container {
  margin-top: 4rem;
  width: 100%;
  border-radius: 34px;
  background: #f9f9f9;
  padding: 50px;
}
.recall-store-landing-section-four-large-text {
  color: #313131;
  font-size: 45.224px;
  font-weight: 600;
  line-height: 130%; /* 58.791px */
  letter-spacing: -0.904px;
  display: flex;
  margin-top: 2rem;
  align-items: center;
  gap: 1rem;
}
.recall-store-landing-section-four-large-text img {
  width: 40px;
  transform: rotate(-45deg);
}
.recall-store-landing-section-four-featured {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
  width: 100%;
}
.recall-store-landing-section-four-featured-item {
  border-radius: 32px;
  border: 0.869px solid #d7d7d7;
  padding: 11px;
  width: 100%;
  transition: all 0.3s ease-in-out;
}
.recall-store-landing-section-four-featured-item-img {
  width: 100%;
  border-radius: 21.09px;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.recall-store-landing-section-four-featured-item-img img {
  width: 80%;
}
.recall-store-landing-section-four-featured-item-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recall-store-landing-section-four-featured-item-info-name {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: 130%; /* 23.4px */
  letter-spacing: -0.36px;
  margin-top: 5px;
}
.recall-store-landing-section-four-featured-item-info-price {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 130%; /* 36.4px */
  letter-spacing: -0.56px;
}

.recall-store-landing-section-four-featured-item-info-price span {
  font-family: "Inter", sans-serif;
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -0.28px;
}
.recall-store-landing-section-four-featured-item-desc {
  color: rgba(40, 40, 40, 0.75);
  font-size: 15px;
  font-weight: 500;
  line-height: 130%; /* 16.9px */
  letter-spacing: -0.26px;
  margin-top: 13px;
}
.recall-store-landing-section-four-featured-item button {
  width: 100%;
  border-radius: 24px;
  background: #89cff0;
  padding: 12px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 18px;
  justify-content: center;
  letter-spacing: -0.32px;
}

.recall-store-landing-section-four-featured-item button img {
  width: 20px;
  transition: all 0.3s ease-in-out;
}

.recall-store-landing-section-four-featured-item:hover {
  transform: scale(1.01);
}

.recall-store-landing-section-four-featured-item:hover button img {
  transform: translateX(10px);
}
.recall-store-landing-section-five {
  margin-top: 10rem;
}

.recall-landing-page-section-3-cards {
  width: 100%;
  margin: 0 auto;
  display: flex;
  margin-bottom: 5rem;
  margin-top: 5rem;
  gap: 1.5rem;
}
.number-pill-text {
  position: absolute;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 51.072px;
  display: flex;
  align-content: center;
  justify-content: center;
  text-align: center;
  left: 6%;
  top: 14px;
  font-weight: 600;
  line-height: 163%; /* 34.348px */
  letter-spacing: -0.632px;
}
.recall-landing-page-section-3-card {
  position: relative;
  border: 1.5px solid #dcdde0;
  width: 30%;
  padding: 12px;
  border-radius: 17.854px;
}
.recall-landing-page-section-3-card-img {
  width: 100%;
}
.recall-landing-page-section-3-card-img img {
  width: 100%;
}
.recall-landing-page-section-3-card-text {
  margin-top: 10px;
}
.recall-landing-page-section-3-card-text-header {
  color: #333;
  font-size: 28px;
  opacity: 0.9;
  font-weight: 600;
  line-height: 110%; /* 31.423px */
  letter-spacing: -0.857px;
}
.recall-landing-page-section-3-card-text-body {
  color: #5e5e5e;
  font-size: 15px;
  font-weight: 400;
  line-height: 133%;
  letter-spacing: -0.319px;
}
.recall-store-landing-section-six {
  background-color: #89cff0;
  padding: 80px 0;
  position: relative;
  margin-top: 150px;
  overflow: hidden;
}
.recall-store-landing-section-six-container-flex-img img {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 20%;
}
.recall-store-landing-section-six-header {
  color: #fff;
  font-size: 36px;
  margin-top: 45px;
  font-weight: 600;
  line-height: 130%; /* 46.8px */
  letter-spacing: -1.08px;
  width: 50%;
}
.recall-store-landing-section-six-header-description {
  color: rgba(255, 255, 255, 0.8);
  font-size: 20px;
  font-weight: 500;
  line-height: 165%; /* 33px */
  letter-spacing: -0.4px;
  width: 50%;
  margin-top: 10px;
  margin-bottom: 30px;
}
.recall-store-landing-section-six-container-flex button {
  border-radius: 6px;
  border: 1px solid #fff;
  background: #fff;
  box-shadow: 0px 0px 31px 0px rgba(0, 0, 0, 0.1);
  color: #89cff0;
  font-size: 18.39px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.368px;
  padding: 12px 45px;
}

.recall-landing-page-section-5-cards {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 80px;
  width: 80%;
  gap: 2rem;
}
.recall-landing-page-section-5-card {
  width: 30%;
  border-radius: 17.825px;
  text-align: center;
  border: 1px solid #d1d1d1;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.recall-landing-page-section-5-card:hover {
  transform: scale(1.02);
}
.recall-landing-page-section-5-card .arrow {
  transition: all 0.3s ease-in-out;
}
.recall-landing-page-section-5-card:hover .arrow {
  transform: translateX(5px);
}
.recall-landing-page-section-5-card-image {
  width: 100%;
}
.recall-landing-page-section-5-card-image img {
  width: 100%;
}
.recall-landing-page-section-5-card-text {
  color: #231d4f;
  margin-top: 15px;
  display: flex;
  text-align: center;
  align-items: center;
  gap: 10px;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.36px;
}
.recall-store-landing-section-seven {
  margin-top: 10rem;
}

.recall-store-landing-section-eight {
  margin-top: 10rem;
  margin-bottom: 8rem;
}
.recall-store-landing-section-eight {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.contact-us-flex {
  display: grid;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-top: 5rem;
  grid-template-columns: 1fr 0.8fr;
}
.contact-us-right {
  background: linear-gradient(152.4deg, #0ab2f9 5.68%, #1f5df9 73.16%);
  border-radius: 41.6694px;
  overflow: hidden;
  width: 100%;
}
.contact-us-flex > div {
  width: 100%;
}
.contact-us-flex > div img {
  width: 100%;
}
.contact-us-left {
  text-align: left;
  width: 100%;
}

.input {
  width: 100%;
  margin-bottom: 23px;
}
.input input,
.input textarea {
  width: 90%;
  background: #ffffff;
  border: 1.5626px solid rgba(0, 0, 0, 0.15);
  border-radius: 10.4174px;
  padding: 15px 28px;
  font-weight: 500;
  font-size: 15.8347px;
  line-height: 21px;

  color: rgba(0, 0, 0, 0.6);
}
.input p {
  font-weight: 500;
  font-size: 18.8347px;
  line-height: 31px;

  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 12px;
}
.input textarea {
  height: 120px;
  resize: none;
}
.send-btn button {
  background: #89cff0 !important;
  border-radius: 11.373px !important;
  box-shadow: 0px 0px 18.480693817138672px 1.4215917587280273px
    rgba(0, 0, 0, 0.15);
  padding: 10px 32px;
  color: #fff;
  font-size: 18.196px;
  font-weight: 500;
  letter-spacing: -0.182px;
}

.footer-signup {
  display: flex;
  background: #f9fafb;
  padding: 70px 10%;
  align-items: flex-end;
  justify-content: space-between;
}
.footer-signup-left .recall-landing-page-section-1-text-small {
  font-size: 18px;
}
.recall-landing-page-section-1-text-large {
  color: rgba(18, 24, 38, 0.79);
  font-size: 64px;
  font-weight: 600;
  line-height: 70.91px; /* 103.804% */
  letter-spacing: -2.049px;
}
.footer-signup-left .recall-landing-page-section-1-text-large {
  font-size: 42px;
}
.footer-sgnup-cta {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.footer-sgnup-cta button {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  line-height: 23.95px;
  letter-spacing: -0.66px;
  border: none;
  background: #89cff0;
  padding: 12px 24px;
}

.footer-sgnup-cta p {
  color: #000;
  font-size: 22px;
  font-weight: 500;
  line-height: 23.95px; /* 108.865% */
  letter-spacing: -0.66px;
}
.footer-content {
  background-color: #89cff0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 70px 10%;
  padding-bottom: 120px;
}
.footer-cta button {
  background-color: #fff;
  color: #89cff0;
}
.footer-content-left .recall-landing-page-section-1-text-medium {
  font-size: 20px;
  color: #fff;
  opacity: 0.8;
  margin-bottom: 60px;
}
.recall-landing-page-section-1-text-medium a{
  color: #fff;
}
.footer-content-right-social {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.footer-content-right-social img {
  opacity: 0.8;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.footer-content-right-social img:hover {
  cursor: pointer;
  transform: translateY(-10px);
}
.privacy-links {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 2.5rem;
}
.privacy-links a {
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  font-weight: 500;
  line-height: 21.695px; /* 135.591% */
  letter-spacing: -0.48px;
}
.footer-content-right-links {
  display: flex;
  gap: 4rem;
}
.footer-content-right-links {
  margin-bottom: 2.5rem;
}
.footer-content-right-links-item-header {
  font-size: 19px;
  font-weight: 600;
}
.principle-modal {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 2rem;
  gap: 2rem;
}

.principle-modal-img {
  width: 100%;
  height: 100%;
}
.principle-modal-img img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}
.principle-modal-header {
  color: #231d4f;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.44px;
}
.principle-modal-desc {
  color: #434343;
  font-size: 16px;
  margin-top: 8px;
  font-weight: 500;
  letter-spacing: -0.32px;
  opacity: 0.65;
}
.principle-modal-text {
  width: 95%;
  margin: 0 auto;
  margin-top: 1rem;
}
.footer-content-right-links-item-links {
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  gap: 10px;
}
.footer-content-right-links-item-links p {
  font-weight: 500;
  opacity: 0.75;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.footer-content-right-links-item-links p:hover {
  opacity: 1;
  transform: translate(5px);
}
.footer-content-right-links-item {
  color: #fff;
}
.product-not-found {
  text-align: center;
  margin: 6rem 0;
  margin-bottom: 8rem;
}
.product-not-found img {
  margin-bottom: 2rem;
}
.product-not-found h1 {
  color: #000;
  font-size: 36px;
  font-weight: 600;
  line-height: 130%; /* 46.8px */
  letter-spacing: -1.08px;
}

.product-not-found p {
  color: rgba(46, 46, 46, 0.7);
  font-size: 22px;
  margin-top: 14px;
  font-weight: 500;
  line-height: 130%; /* 28.6px */
  letter-spacing: -0.66px;
}
.filters-wrap {
  position: relative;
  min-height: 70px;
}
.filters-wrap.sticky .recall-store-filter {
  position: fixed;
  width: 90%;
  box-shadow: 0px 0px 46px 0px rgba(0, 0, 0, 0.35);
  z-index: 9;
  top: 40px;
}
.recall-store-checkout-content {
  width: 90%;
  margin: 4rem auto;
  margin-bottom: 8rem;
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  gap: 7rem;
}

.recall-store-summary {
  margin-left: auto;
  width: 80%;
  border-radius: 10.866px;
  border: 1.758px dashed #d2d2d2;
  padding: 17px;
  padding-top: 30px;
  padding-left: 28px;
  padding-right: 28px;
  margin-top: 3rem;
}
.recall-store-summary-item,
.recall-store-grand-total {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.redeem-points-summary {
  display: flex;
  align-items: center;
  gap: 10px;
}
.recall-store-grand-total {
  padding-top: 10px;
  border-top: 2px dashed #000;
}
.recall-store-summary-items {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 14px;
}
.recall-store-summary-item p:nth-child(1) {
  color: #7b7b7b;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: -0.335px;
}
.recall-store-summary-items button {
  padding: 10px;
  border-radius: 6.791px;
  background: #89cff0;
  border: none;
  color: #fff;
  font-size: 16.438px;
  font-weight: 500;
  letter-spacing: -0.329px;
}
.recall-store-impact-summary {
  width: 80%;
  margin-top: 1.5rem;
  margin-left: auto;
  border-radius: 10.866px;
  border: 1.558px solid #ececec;
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 1rem;
}
.recall-store-impact-summary-img {
  border-radius: 11px;
  border: 1px solid #e9e9e9;
  background: #f4f4f4;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.recall-store-impact-summary-text {
  color: #505050;
  font-size: 18px;
  font-weight: 600;
  line-height: 130%; /* 23.4px */
  letter-spacing: -0.54px;
}

.menu-links {
  margin-top: 10px;
  /* margin-left: 10px; */
}
.menu-links-item-text p {
  color: #454545;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.28px;
}
.menu-links-item-icon {
  border-radius: 8.601px;
  background: #f4f4f4;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}
.menu-links-item {
  display: flex;
  gap: 6px;
  cursor: pointer;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.menu-modal {
  gap: 0.4rem;
}
.menu-links-item:hover {
  transform: translateX(5px);
}
.menu-modal > p {
  font-size: 13px;
  font-weight: 500;
}
.menu-links {
  width: 100%;
}
.menu-buttons {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 12px;
}

.menu-buttons button {
  width: 100%;
  border-radius: 6.055px;
  padding: 9px;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%; /* 15.6px */
  letter-spacing: -0.39px;
  background: #89cff0;
}
.menu-buttons button.secondary {
  background: #fff;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.75);
}
.dynamic-ogimage {
  width: 650px;
  height: 318px;
  border: 1px solid #000;
  padding: 33px;
  background: linear-gradient(93deg, #eff8fd 0.61%, #fff 96.49%);
}
.dynamic-ogimage h1 {
  gap: 10px;
  display: flex;
  align-items: center;
  color: #444954;
  font-size: 29px;
  font-style: normal;
  font-weight: 600;

  line-height: normal;
  letter-spacing: -0.405px;
}
.dynamic-ogimage h1 img {
  width: 45px;
}
.hide-og {
  position: absolute;
  left: -1000px;
}
.dynamic-ogimage p {
  margin-top: 22px;
  color: #444954;
  font-size: 18px;
  opacity: 0.7;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.category-others {
  display: none;
}
.all-categories-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
  margin-top: 3rem;
}
.all-categories-list > div {
  width: 100%;
}
.all-categories-list-item {
  border-radius: 30px;
  /* border: 1.2px solid #dcdde0; */
  background: #f3f3f3;
  padding: 12px;
  display: flex;
  gap: 24px;
}
.all-categories-list-img {
  width: 24%;
}
.all-categories-list-img img {
  width: 100%;
  display: flex;
}
.all-categories-list-name h1 {
  color: #323232;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.172px;
}
.all-categories-list-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-top: 8px;
}
.all-categories-list-item-product {
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;
  color: #4d4d4d;
  font-size: 16px;
  opacity: 0.7;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 20.614px */
  letter-spacing: -0.476px;
  transition: all 0.2s ease-in-out;
}
.all-categories-list-item-product:hover {
  opacity: 1;
  transform: translate(5px);
}
.all-categories-list-name-mobile h1 {
  display: none;
}
.recall-store-landing-section-four-featured
  .recall-store-landing-section-four-featured-item:last-child:last-child {
  display: none;
}
@media (max-width: 1300px) {
  .recall-store-impact-summary {
    width: 100%;
  }
  .recall-store-summary {
    width: 100%;
  }
  .recall-store-checkout-content {
    gap: 2rem;
  }
  .recall-store-landing-section-four-featured
    .recall-store-landing-section-four-featured-item:last-child:last-child {
    display: block;
  }
  .all-categories-list-name h1 {
    font-size: 26px;
  }
  .all-categories-list-item-product {
    font-size: 14px;
  }
  .all-categories-list-items {
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }
  .all-categories-list-item-product {
    font-size: 16px;
  }
  .all-categories-list-img {
    width: 20%;
  }
  .all-categories-list {
    grid-template-columns: repeat(1, 1fr);
  }
  .recall-store-landing-section-heading-description {
    font-size: 18px;
  }
  .recall-store-landing-section-four-featured {
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
  }
  .recall-store-landing-section-heading h1 {
    font-size: 42px;
  }
  .recall-store-categories-list {
    gap: 1rem;
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 1200px) {
  .recall-store-checkout-content {
    grid-template-columns: repeat(1, 1fr);
  }
  .recall-landing-page-section-5-cards {
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;
    margin-top: 30px;
    justify-content: center;
  }

  .recall-store-landing-section-seven {
    margin-top: 5rem;
  }
  .recall-landing-page-section-5-card {
    width: 45%;
  }
  .footer-content-left .recall-landing-page-section-1-text-medium {
    width: 390px;
  }
  .footer-signup,
  .footer-content {
    padding: 50px 7%;
  }
  .footer-content-left img {
    width: 210px;
  }
  .footer-signup-left .recall-landing-page-section-1-text-large {
    font-size: 36px;
  }
  .recall-store-landing-section-three-right button {
    margin-top: 20px;
    padding: 8px 40px;
  }
  .recall-store-landing-section-heading h1 {
    font-size: 42px;
  }
  .recall-store-landing-section-one {
    padding: 120px 3rem;
  }
}
@media (max-width: 900px) {
  .recall-store-landing-section-three-flex {
    margin-top: 2rem;
  }
  .recall-store-landing-section-four-large-text {
    display: none;
  }
  .recall-store-landing-section-six-header-description {
    line-height: 130%;
    font-weight: 400;
    opacity: 1;
  }
  .recall-store-landing-section-eight {
    width: 90%;
    margin-top: 5rem;
  }

  .footer-content-right-links-item-header {
    font-size: 17px;
  }
  .recall-store-landing-section-six {
    margin-top: 90px;
  }
  .recall-store-landing-section-three {
    margin-top: 5rem;
  }
  .recall-landing-page-section-3-cards {
    margin-top: 2rem;
  }
  .recall-store-landing-section-five {
    margin-top: 5rem;
  }
  .recall-store-landing-section-four-container {
    margin-top: 2rem;
  }
  .recall-store-landing-section-heading-description {
    margin-top: 5px;
  }
  .recall-store-landing-section-four {
    margin-top: 5rem;
  }
  .recall-store-landing-section-heading h1 {
    margin-top: 10px;
  }
  .principle-modal {
    flex-direction: column;
    gap: initial;
    padding: 0;
  }
  .principle-modal-list li {
    color: rgba(0, 0, 0, 0.65);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.32px;
  }
  .all-categories-list-items {
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }
  .all-categories-list-img {
    width: 34%;
  }
  .all-categories-list-item-product {
    font-size: 14px;
  }
  .recall-store-landing-section-six-header,
  .recall-store-landing-section-six-header-description {
    width: 100%;
  }
  .recall-store-landing-section-six-container-flex button {
    font-size: 16px;
    padding: 8px 40px;
  }

  .recall-store-landing-section-six-header {
    font-size: 26px;
  }
  .recall-store-landing-section-six {
    padding-bottom: 140px;
  }
  .recall-store-landing-section-six-container-flex-img img {
    width: 40%;
  }
  .recall-store-landing-section-six-header-description {
    font-size: 18px;
  }
  .recall-landing-page-section-3-cards {
    flex-wrap: wrap;
  }
  .recall-landing-page-section-3-card-text-header {
    font-size: 22px;
  }
  .recall-landing-page-section-3-card-text-body {
    font-size: 14px;
  }
  .number-pill-text {
    font-size: 36px;
    left: 11%;
  }
  .recall-landing-page-section-5-card {
    width: 76%;
  }
  .recall-landing-page-section-3-card {
    width: 46%;
  }
  .recall-store-landing-section-four-container {
    padding: 20px;
  }
  .recall-store-landing-section-four-featured-item-info-price {
    font-size: 22px;
  }
  .recall-store-landing-section-four-featured-item button {
    padding: 9px;
    font-size: 14px;
  }
  .recall-store-landing-section-four-featured-item-info-name {
    font-size: 16px;
  }
  .recall-store-landing-section-four-featured-item-desc {
    font-size: 13px;
    margin-top: 6px;
  }
  .footer-signup-left .recall-landing-page-section-1-text-large {
    font-size: 28px;
  }
  .footer-sgnup-cta {
    margin-top: 25px;
  }
  .footer-sgnup-cta button {
    font-size: 16px;
  }
  .footer-signup {
    flex-direction: column;
    align-items: flex-start;
  }
  .footer-content {
    flex-direction: column;
    align-items: flex-start;
  }
  .footer-content-left {
    margin-bottom: 30px;
  }
  .recall-store-landing-section-three-flex {
    flex-direction: column;
  }
  .recall-store-landing-section-three-flex > div {
    width: 100%;
  }
  .recall-store-landing-section-three-right div br {
    display: none;
  }
  .recall-store-landing-section-three-left img {
    width: 100%;
  }
  .recall-store-landing-section-three-right button {
    font-size: 16px;
  }
  .recall-store-landing-section-four-large-text {
    font-size: 36px;
  }
  .contact-us-flex {
    display: flex;
    flex-direction: column-reverse;
  }
  .contact-right img {
    height: 220px;
    border-radius: 20px;
    object-fit: cover;
  }
  .contact-us-flex {
    margin-top: 2rem;
  }
  .contact-us-left {
    margin-top: 14px;
  }
  .input input,
  .input textarea {
    width: 100%;
    padding: 6px;
    padding-left: 16px;
  }
  .input input::placeholder,
  .input textarea::placeholder {
    font-size: 14px;
  }
  .input p {
    margin-bottom: 0px;
    font-size: 14px;
  }
  .input {
    margin-bottom: 12px;
  }
}
@media (max-width: 800px) {
  .other-categories {
    display: none;
  }
  .category-others-text {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .category-others-text img {
    width: 30px;
    transition: all 0.5s ease-in-out;
  }
  .category-others:hover .category-others-text img {
    transform: rotate(-45deg) scale(1.05);
  }
  .category-others-text p {
    color: #000;
    font-size: 20px;
    margin-top: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 27.7px; /* 138.5% */
    letter-spacing: -0.6px;
  }
  .category-others {
    cursor: pointer;
    margin-top: 14px;
    display: flex;
    gap: 1rem;
    border-radius: 6px;

    border: 1.2px solid #d5d5d5;
    padding: 4px;
    padding-right: 20px;
  }
  .recall-store-landing-section-heading-content {
    max-width: unset;
    width: 100%;
  }
  .recall-store-categories-list {
    grid-template-columns: repeat(3, 1fr);
  }
  .recall-store-categories-list-item
    .recall-store-categories-list-item-info
    img {
    width: 20px;
  }
  .recall-store-categories-list-item-info {
    font-size: 14px;
  }
  .recall-store-landing-section-one-search img {
    bottom: 14px;
    width: 20px;
  }
  .recall-store-landing-section-one-search button {
    padding: 8px 32px;
  }
  .recall-store-landing-section-one-search input {
    padding: 8px;

    padding-left: 40px;
  }
  .recall-store-landing-section-one-search {
    width: 90%;
  }
  .recall-store-landing-section-heading h1 {
    font-size: 36px;
  }
}
@media (max-width: 700px) {
  .recall-addded-item-price{
    display: inline-block;
  }
  .all-categories-list {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    margin: 0 auto;
    margin-top: 3rem;
  }
  .all-categories-list-img {
    display: flex;
    width: 100%;
    gap: 16px;
  }
  .all-categories-list-img img {
    width: 100%;
  }
  .all-categories-list-img {
    width: 100%;
  }
  .all-categories-list-name h1 {
    /* display: none; */
  }
  .all-categories-list-name-mobile h1 {
    /* display: block; */
    font-size: 20px;
    line-height: 125%;
  }
  .all-categories-list-items {
    margin-bottom: 1rem;
    padding: 4px;
  }
  .all-categories-list-items {
    grid-template-columns: repeat(2, 1fr);
  }
  .all-categories-list-item {
    gap: 1px;
  }
  .all-categories-list-item {
    border: none;
    background-color: #f3f3f3;
    border-radius: 10px;
  }
  .all-categories-list {
    gap: 1.2rem;
  }
  .all-categories-list-item-product {
    font-size: 14px;
  }
  .all-categories-list-item {
    flex-direction: column;
  }
  .recall-landing-page-section-5-card {
    width: 100%;
  }
  .recall-store-landing-section-four-featured {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 600px) {
  .category-others-text p {
    font-size: 18px;
  }
  .footer-content-left .recall-landing-page-section-1-text-medium {
    width: unset;
  }
  .recall-store-landing-section-four-container {
    background: #fff;
    padding: 0;
  }
  .recall-store-landing-section-four-featured-item-info-name {
    font-size: 13px;
  }
  .recall-store-landing-section-four-featured-item-img {
    border-radius: 8px;
  }
  .recall-store-landing-section-four-featured-item button {
    border-radius: 10px;
    padding: 6px;
  }
  .recall-store-landing-section-four-featured-item {
    padding: 8px;
    border-radius: 18px;
  }
  .recall-store-landing-section-four-featured-item-info-price span {
    font-size: 10px;
  }
  .recall-store-landing-section-four-featured-item-info-price {
    font-size: 19px;
  }
  .recall-store-landing-section-four-featured-item button img {
    width: 16px;
  }
  .recall-store-landing-section-four-featured-item-desc {
    font-size: 12px;
    font-family: "Inter", sans-serif;
  }
  .recall-store-landing-section-four-featured {
    gap: 0.8rem;
  }
  .recall-landing-page-section-3-card {
    width: 100%;
  }
  .recall-store-landing-section-four-large-text {
    font-size: 28px;
  }
  .recall-store-landing-section-four-large-text img {
    width: 30px;
  }
  .footer-content-right-links-item-links {
    gap: 3px;
  }
  .footer-content-left .recall-landing-page-section-1-text-medium {
    margin-bottom: 20px;
    font-size: 16px;
  }
  .footer-content-right-social {
    gap: 1rem;
  }
  .footer-cta button {
    padding: 8px 24px;
  }
  .recall-store-categories-list {
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }
  .recall-store-categories-list-item {
    border-radius: 12px;
    padding: 4px;
  }
  .recall-store-landing-section-one {
    padding: 90px 1rem;
    padding-bottom: 60px;
    background-position: 41%;
  }
  .recall-store-categories-list-item
    .recall-store-categories-list-item-info
    img {
    width: 15px;
  }
  .recall-store-categories-list-item-info {
    font-size: 13px;
  }
  .recall-store-landing-section-heading h1 {
    font-size: 28px;
    line-height: 127%;
  }
  .recall-store-landing-section-heading-description {
    font-size: 16px;
    line-height: 130%;
  }
  .recall-store-landing-section-one-search input {
    font-size: 14px;
  }
  .header-text-text {
    font-size: 22px !important;
  }
  .recall-store-landing-section-one-search {
    width: 100%;
  }
  .recall-store-landing-section-one-search img {
    bottom: 10px;
  }
  .recall-store-landing-section-one-search button {
    padding: 6px 32px;
    font-size: 16px;
  }
  .recall-store-landing-section-one-search input::placeholder {
    font-size: 14px;
  }
  .footer-content-right-links-item-links p {
    font-size: 13px;
  }
  .footer-content-right-links-item-links {
    margin-top: 3px;
  }
  .send-btn button {
    font-size: 16px;
    padding: 6px 30px;
  }
}
